@font-face {
  font-family: 'BodoniStdPosterItalic';
  src: local('BodoniStdPosterItalic'), url(./fonts/BodoniStdPosterItalic.otf) format('opentype');
}

/* General */

body {
  background-color: black;
  margin: 0;
  user-select: none;
  line-height: 1;
  /* overscroll-behavior-y: contain; */
  font-family: aktiv-grotesk,sans-serif;
  font-weight: 400;
  font-style: normal;
}
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.message {
  padding: 20px;
  color: #868686;
  font-size: 25px;
}
.message a,
.message a:visited,
.message a:hover,
.message a:active {
  color: #637a8e;
  text-decoration: none;
}
*:focus {
  outline: none !important;
}
.hide {
  display: none;
}
/* .flipped {
  transform: scaleX(-1);
} */


/* Home */

.home {
  /* overflow-y: scroll; */
  background-color: black;
  height: 1200px;
  width: 1920px;
  position: relative;
}
.home--header h1 {
  background-color: black;
  color: #92bdb3;
  margin: 25px 0 25px 0;
  text-align: center;
  font-family: BodoniStdPosterItalic;
}
.home--header h1 span {
  text-transform: uppercase;
}
.home--main {
  background-color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin: 10px;
  padding-bottom: 10px;
}
.home--main--thumbnail {
  list-style-type: none;
  margin-bottom: 10px;
}
.home--main--thumbnail .imageWrapper {
  margin-bottom: 5px;
}
.home--main--thumbnail .imageWrapper img {
  object-fit: cover;
  height: 248px;
  width: 372px;
  border-radius: 10px;
}
.home--main--thumbnail a,
.home--main--thumbnail a:visited,
.home--main--thumbnail a:hover,
.home--main--thumbnail a:active {
  color: white;
  text-decoration: none;
  font-size: 16px;
}
.home--scroll {
  display: none;
  position: fixed;
  bottom: 50px;
  color: white;
  left: 50%;
  transform: translateX(-50%);
}
.home--scroll img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(0%) hue-rotate(320deg) brightness(107%) contrast(105%);
  height: 90px;
}
.home--scroll .icon-label {
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: center;
}

/* Card */

.card {
  /* overflow-y: scroll; */
  background-color: black;
  height: 1200px;
  width: 1920px;
}
.carousel {
  height: 1100px;
}
.kvfysmfp {
  height: 1100px;
}
/* .card--image {
  height: 1050px;
}
.card--image-full {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1200px;
}
.card--image img, .card--image-full img {
  max-width: 100%;
  max-height: 100%;
} */
.card--controls {
  display: flex;
  justify-content: space-between;
  background-color: #1C1C1C;
  color: white;
  font-size: 24px;
  padding: 0 35px;
  height: 100px;
}


.card--controls--left {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.card--controls--left--back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card--controls--left--back--icon {
  height: 30px;
  margin-bottom: 10px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(0%) hue-rotate(320deg) brightness(107%) contrast(105%);
}
.card--controls--left--back--label {
  font-size: 16px;
}

.card--controls--left--flip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 45px;
}
.card--controls--left--flip--icon {
  height: 30px;
  margin-bottom: 10px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(0%) hue-rotate(320deg) brightness(107%) contrast(105%);
}
.card--controls--left--flip--label {
  font-size: 16px;
}


.card--controls--center {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #868686;
  cursor: pointer;
}


.card--controls--right {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}
.card--controls--right--text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card--controls--right--text--icon {
  height: 47px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(0%) hue-rotate(320deg) brightness(107%) contrast(105%);
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.card--controls--right--text--label {
  font-size: 28px;
}


/* Transcription */

.transcription-wrapper {
  min-height: 100%;
  background-color: #1C1C1C;
  color: white;
}
.transcription {
  padding: 30px;
}
.transcription--close {
  text-align: right;
}
.transcription--close img {
  height: 30px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(0%) hue-rotate(320deg) brightness(107%) contrast(105%);
  cursor: pointer;
}
.transcription--content {
  margin: 25px 60px 0px 60px;
}
.transcription--content hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #444;
  margin: 20px 0;
  padding: 0;
}
.transcription--content--heading {
  color: #868686;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 20px 0;
}
.transcription--content--card-box {
  margin-bottom: 20px;
}
.transcription--content--card-box:last-of-type {
  margin-bottom: 30px;
}
.transcription--content--card-box--heading {
  color: #868686;
  font-size: 25px;
  float: right;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 7px 0;
}
.transcription--content--card-box--inner {
  border: 2px solid #444;
  border-radius: 5px;
  padding: 20px;
  clear: both;
}
.transcription--content--field:not(:last-of-type) {
  margin-bottom: 20px;
}
.transcription--content--field .translation {
  margin-top: 0;
}
.transcription--content--field h3 {
  color: #868686;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.3;
  margin: 0;
  text-transform: uppercase;
}
.transcription--content--field p {
  font-size: 25px;
  line-height: 1.3;
  margin: 0;
}
.transcription--content--field div {
  margin: 0;
}
.transcription--content--visitor-name {
  font-size: 38px;
  margin: 0 0 30px 0;
}
